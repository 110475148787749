import React from 'react'
// API calls
import { getEnterpriseProfileFunctionalities, saveProfile } from '../../Models/RolModel'
// Tools
import { getJwt } from '../../../../lib/auth'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import $ from "jquery"
import Swal from 'sweetalert2'
import * as toastr from 'toastr'

const AdminRolTable = ({ roles, setSection, structureData, setRolData, rolData, refreshTableData, setIsGenericRol, functionsArr, justRead }) => {
    const { SearchBar } = Search;
    const columns = [
        {
            dataField: 'description',
            text: 'Nombre',
            align: 'center',
            headerAlign: 'center',
        }, {
            dataField: 'idPerzonalizedProfile',
            text: '',
            align: 'center',
            headerAlign: 'center',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                if (cell > 0) {
                    if (parseInt(cell) > 6) {
                        if (justRead) {
                            return (
                                <>
                                    <i
                                        className='bi bi-eye pointer text-pink-mine font-weight-bold'
                                        onClick={() => getEnterpriseProfileFunctionalities(getJwt('enterprise'), cell).then(response => {
                                            setRolData({
                                                idProfile: parseInt(cell),
                                                idUser: parseInt(getJwt('jtw')),
                                                description: row.description,
                                                idEnterprise: parseInt(getJwt('enterprise')),
                                                active: 1,
                                                functionalities: [],
                                            })
                                            setIsGenericRol(true)
                                            structureData(response.functions)
                                        })}
                                    />
                                </>
                            )
                        } else {
                            return (
                                <>
                                    <i
                                        className='bi bi-pencil pointer text-pink-mine font-weight-bold mr-1'
                                        onClick={() => getEnterpriseProfileFunctionalities(getJwt('enterprise'), cell).then(response => {
                                            setRolData({
                                                idProfile: parseInt(cell),
                                                idUser: parseInt(getJwt('jtw')),
                                                description: row.description,
                                                idEnterprise: parseInt(getJwt('enterprise')),
                                                active: 1,
                                                functionalities: [],
                                            })
                                            setIsGenericRol(false)
                                            structureData(response.functions)
                                        })}
                                    />
                                    <i
                                        className='bi bi-trash pointer text-danger font-weight-bold ml-1'
                                        onClick={() => {
                                            Swal.fire({
                                                title: '<p class="text-white mb-2">¿Deseas Continuar?</p>',
                                                text: "Una vez eliminado el rol, no se podrá recuperar.",
                                                showCloseButton: true,
                                                showCancelButton: true,
                                                focusCancel: true,
                                                cancelButtonText: 'Cancelar',
                                                showConfirmButton: true,
                                                confirmButtonText: "Si, eliminar",
                                                reverseButtons: true,
                                                dangerMode: true
                                            }).then((willDelete) => {
                                                if (willDelete.isConfirmed) {
                                                    let data = {
                                                        ...rolData,
                                                        active: 0,
                                                        idProfile: parseInt(cell),
                                                        description: row.description,
                                                    }
                                                    saveProfile(data).then(response => {
                                                        switch (response.code) {
                                                            case 1:
                                                            case 200:
                                                                refreshTableData()
                                                                toastr.success('Se eliminó correctamente.', '¡Éxito!')
                                                                break;
                                                            default:
                                                                toastr.error('No se pudo eliminar. Intenta nuevamente.', '¡Ooops!')
                                                                break;
                                                        }
                                                    })
                                                }
                                            });
                                            $(".swal2-title").addClass("bg-header-swal");
                                            $(".swal2-actions").addClass("w-100 justify-content-around");
                                            $(".swal2-confirm").removeClass("swal2-styled");
                                            $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                                            $(".swal2-cancel").removeClass("swal2-styled");
                                            $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
                                        }}
                                    />
                                </>
                            )
                        }
                    } else {
                        return (
                            <i
                                className='bi bi-eye pointer text-pink-mine font-weight-bold'
                                onClick={() => {
                                    getEnterpriseProfileFunctionalities(0, cell).then(response => {
                                        setRolData({
                                            idProfile: parseInt(cell),
                                            idUser: parseInt(getJwt('jtw')),
                                            description: row.description,
                                            idEnterprise: parseInt(getJwt('enterprise')),
                                            active: 1,
                                            functionalities: [],
                                        })
                                        setIsGenericRol(true)
                                        structureData(response.functions)
                                    })
                                }}
                            />
                        )
                    }
                }
            },
        },
    ]

    const options = {
        custom: true,
        sizePerPage: 10,
        firstPageText: 'Primera',
        prePageText: 'Atrás',
        nextPageText: 'Siguiente',
        lastPageText: 'Última',
        nextPageTitle: 'Primera página',
        prePageTitle: 'Página anterior',
        firstPageTitle: 'Siguiente página',
        lastPageTitle: 'Última página',
        showTotal: true,
        //totalSize: operations.length,
    }

    const contentTable = ({ paginationProps, paginationTableProps }) => (
        <div>
            <ToolkitProvider
                keyField="idPerzonalizedProfile"
                data={roles}
                columns={columns}
                search
            >
                {
                    props => (
                        <div className='row'>
                            <div className='col-4 complete-searchBar mb-3'>
                                <SearchBar
                                    {...props.searchProps}
                                    placeholder="Buscar"
                                    className='form-control form-control-sm'
                                />
                            </div>
                            <div className='col-8 mb-3 d-flex justify-content-end flex-wrap align-content-center'>
                                {
                                    !justRead && (
                                        <button
                                            className='btn btn-sm bg-pink-mine text-white'
                                            onClick={() => {
                                                structureData(functionsArr)
                                                setSection(2)
                                            }}
                                        >Crear Rol</button>
                                    )
                                }
                            </div>
                            <div className='col-8 mx-auto'>
                                <BootstrapTable
                                    {...props.baseProps}
                                    striped
                                    hover
                                    bordered={false}
                                    {...paginationTableProps}
                                /* classes='shadow-1 mb-3' */
                                />
                                <PaginationListStandalone {...paginationProps} />
                            </div>
                        </div>
                    )
                }
            </ToolkitProvider>
        </div>
    );

    return (
        <div className='col-12'>
            <PaginationProvider
                pagination={
                    paginationFactory(options)
                }
            >
                {contentTable}
            </PaginationProvider>
        </div>
    )
}

export default AdminRolTable